exports.components = {
  "component---node-modules-gatsby-theme-abstract-blog-src-index-js": () => import("./../../../node_modules/gatsby-theme-abstract-blog/src/index.js" /* webpackChunkName: "component---node-modules-gatsby-theme-abstract-blog-src-index-js" */),
  "component---node-modules-gatsby-theme-abstract-blog-src-post-js": () => import("./../../../node_modules/gatsby-theme-abstract-blog/src/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-abstract-blog-src-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-aseptic-js": () => import("./../../../src/pages/aseptic.js" /* webpackChunkName: "component---src-pages-aseptic-js" */),
  "component---src-pages-fruits-js": () => import("./../../../src/pages/fruits.js" /* webpackChunkName: "component---src-pages-fruits-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vegetables-js": () => import("./../../../src/pages/vegetables.js" /* webpackChunkName: "component---src-pages-vegetables-js" */),
  "slice---src-components-footer-js": () => import("./../../../src/components/footer.js" /* webpackChunkName: "slice---src-components-footer-js" */),
  "slice---src-components-header-js": () => import("./../../../src/components/header.js" /* webpackChunkName: "slice---src-components-header-js" */)
}

